html {
  font-size: 62.5%;

  &.sr .load-hidden {
    visibility: hidden;
  }
}

body {
  font-family: $font-family-base, sans-serif;
  text-align: center;
  scroll-behavior: smooth;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: $light-grey;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
