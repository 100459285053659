// body{
//   text-align:center;
//   background-color:#ffcc8e;
// }

.filter-button{
  font-size: 1.6rem;
  position:relative;
  display:inline-block;
  margin: 0.5em;
  color: white;
  font-weight:bolder;
  letter-spacing: 0.1rem;
  text-align: center;
  text-decoration:none;
  background-color:$primary;
  padding:0.625em 1em;
  
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-shadow: 0px 1px 0px #000;
  filter: dropshadow(color=#000, offx=0px, offy=1px);
  
  -webkit-box-shadow:inset 0 1px 0 whitesmoke, 0 10px 0 $primary-dark;
  -moz-box-shadow:inset 0 1px 0 whitesmoke, 0 10px 0 $primary-dark;
  box-shadow:inset 0 1px 0 whitesmoke, 0 10px 0 $primary-dark;
  
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  // transition: all 5s ease-in-out;

}

.filter-button.active{
  top:10px;
  background-color:$primary;
  
  -webkit-box-shadow:inset 0 1px 0 whitesmoke, inset 0 -3px 0 $primary-dark;
  -moz-box-shadow:inset 0 1px 0 whitesmoke, inset 0 -3pxpx 0 $primary-dark;
  box-shadow:inset 0 1px 0 whitesmoke, inset 0 -3px 0 $primary-dark;
  // transition: all 5s ease-in-out;
}


/* Call to Action Button */
.cta-btn {
  display: inline-block;
  position: relative;
  padding: 0.8rem $default-font-size;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  // border-radius: 20px;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  }
}
$btn-color-1: $primary-dark;
$btn-color-2: $primary;

/* Hero Style */
.cta-btn--hero {
  @include supportColorForIE11();
  border-width: 2px;
  border-style: solid;
  -moz-border-image: -moz-linear-gradient(
    135deg,
    $btn-color-1 0%,
    $btn-color-2 100%
  );
  -webkit-border-image: -webkit-linear-gradient(
    135deg,
    $btn-color-1 0%,
    $btn-color-2 100%
  );
  border-image: linear-gradient(
    135deg,
    $btn-color-1 0%,
    $btn-color-2 100%
  );
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
  @include supportIE11() {
    color: $btn-color-2 !important;
    &:hover {
      color: $dark-blue-text !important;
    }
  }

  @include respond(phone) {
    background-image: linear-gradient(
      135deg,
      $btn-color-1 0%,
      $btn-color-2 100%
    );    
    // border: 2px solid $btn-color-2;
    -webkit-text-fill-color: $btn-color-2;
  }

  &::after {
    background-image: linear-gradient(
      135deg,
      $btn-color-1 0%,
      $btn-color-2 100%
    );

    @include respond(phone) {
      -webkit-text-fill-color: $dark-blue-text;
    }
  }

  &:hover {
    -webkit-text-fill-color: $dark-blue-text;
    text-decoration: none;

    @include respond(phone) {
      -webkit-text-fill-color: $btn-color-2;
    }

    &::after {
      width: 100%;
    }
  }
  
  &.active { /* Added selector for active state */
    -webkit-text-fill-color: $white-color;
    text-decoration: underline;

    @include respond(phone) {
      background-image: linear-gradient(
      135deg,
      $btn-color-1 0%,
      $btn-color-2 100%
    );
    }

    &::after {
      width: 100%;
    }
  }
}

/* Resume Style */
.cta-btn--resume {
  color: $white-color;
  border: 2px solid $white-color;

  &::after {
    background: $white-color;
  }

  &:hover {
    color: $btn-color-2;
    text-decoration: none;

    &::after {
      width: 100%;
    }
  }
}

/* Arrow Button */
.up i {
  color: #272727;
}
