.project-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  text-align: left;
}

.project-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.project-container a {
  display: inline-block;
  text-decoration: none;
}


